import SortBy from 'lodash/sortBy'
import Omit from 'lodash/omit'
import Invert from 'lodash/invert'
import { generateId } from '@utils/id'
import CONSTANTS from '@constants'
import {
  transformRecursive,
  transformRecursiveForBuildTree,
} from '@data/recursive'
import {
  transformDependentFieldOptions,
  transformDependentFieldOptionsForServer,
} from '@data/dependent-field-options'
import { getRootTranslator } from '../utils/get-module-translator'
import { isLoggedIn } from '@utils/auth'

export const fieldTypeMap = {
  dropdown: 'DropDownFieldRest',
  radio: 'DropDownFieldRest',
  checkbox: 'CheckBoxFieldRest',
  date: 'DateFieldRest',
  label: 'DisplayFieldRest',
  spacer: 'DisplayFieldRest',
  number: 'NumberFieldRest',
  datetime: 'DateFieldRest',
  textarea: 'TextAreaFieldRest',
  text: 'TextFieldRest',
  section: 'DisplayFieldRest',
  upload: 'AttachmentFieldRest',
  SystemFieldRest: 'SystemFieldRest',
  dependent: 'DependentFieldRest',
  api: 'APIFieldRest',
  rating: 'RatingFieldRest',
  boolean: 'BooleanFieldRest',
  slider: 'SliderFieldRest',
  dynamic: 'DynamicFieldRest',
}

export const getOperatorMaps = () => {
  const __t = getRootTranslator()
  return {
    equal: [{ name: __t('equal'), value: 'equal' }],
    contains: [
      { name: __t('contains'), value: 'contains' },
      { name: __t('is_blank'), value: 'is_blank' },
      { name: __t('is_not_blank'), value: 'is_not_blank' },
    ],
    text: [
      { name: __t('equal_case_insensitive'), value: 'equal_case_insensitive' },
      {
        name: __t('not_equal_case_insensitive'),
        value: 'not_equal_case_insensitive',
      },
      { name: __t('start_with'), value: 'start_with' },
      { name: __t('end_with'), value: 'end_with' },
      { name: __t('contains'), value: 'contains' },
      { name: __t('is_blank'), value: 'is_blank' },
      { name: __t('is_not_blank'), value: 'is_not_blank' },
    ],
    listWithEqual: [
      { name: __t('in'), value: 'in' },
      { name: __t('not_in'), value: 'not_in' },
      { name: __t('equal'), value: 'equal' },
    ],
    list: [
      { name: __t('in'), value: 'in' },
      { name: __t('not_in'), value: 'not_in' },
    ],
    listCaseInsensitive: [
      { name: __t('in'), value: 'in_case_insensitive' },
      { name: __t('not_in'), value: 'not_in_case_insensitive' },
    ],
    in: [{ name: __t('in'), value: 'in' }],
    tag: [
      {
        name: __t('any_member_or_all_members_exist'),
        value: 'any_member_or_all_members_exist',
      },
      { name: __t('all_members_exist'), value: 'all_members_exist' },
    ],
    number: [
      { name: __t('equal'), value: 'equal' },
      { name: __t('not_equal'), value: 'not_equal' },
      { name: __t('greater_than'), value: 'greater_than' },
      { name: __t('less_than'), value: 'less_than' },
      { name: __t('between'), value: 'between' },
    ],
    date: [
      { name: __t('equal'), value: 'equal' },
      { name: __t('greater_than'), value: 'greater_than' },
      { name: __t('less_than'), value: 'less_than' },
      { name: __t('between'), value: 'between' },
      { name: __t('before'), value: 'before' },
      { name: __t('after'), value: 'after' },
    ],
    date_without_greater_than: [
      { name: __t('equal'), value: 'equal' },
      { name: __t('less_than'), value: 'less_than' },
      { name: __t('between'), value: 'between' },
      { name: __t('before'), value: 'before' },
      { name: __t('after'), value: 'after' },
    ],
    boolean: [
      { name: __t('equal'), value: 'equal' },
      { name: __t('not_equal'), value: 'not_equal' },
    ],
    tag_with_no_member: [
      {
        name: __t('any_member_or_all_members_exist'),
        value: 'any_member_or_all_members_exist',
      },
      { name: __t('all_members_exist'), value: 'all_members_exist' },
      { name: __t('no_members_exist'), value: 'no_members_exist' },
    ],
    members_exist_with_not_any_members_exist: [
      {
        name: __t('any_member_or_all_members_exist'),
        value: 'any_member_or_all_members_exist',
      },
      { name: __t('all_members_exist'), value: 'all_members_exist' },
      { name: __t('not_any_members_exist'), value: 'not_any_members_exist' },
    ],
    blank_not_blank: [
      { name: __t('is_blank'), value: 'is_blank' },
      { name: __t('is_not_blank'), value: 'is_not_blank' },
    ],
    null_not_null: [
      { name: __t('is_null'), value: 'is_null' },
      { name: __t('is_not_null'), value: 'is_not_null' },
    ],
    empty_not_empty: [
      { name: __t('is_empty'), value: 'is_empty' },
      { name: __t('is_not_empty'), value: 'is_not_empty' },
    ],
  }
}

export function transformControl(control) {
  return {
    guid: generateId(),
    type: control.type,
    name: control.type,
    icon: 'question',
    isSystemControl: true,
  }
}

export function transformForm(form) {
  return {
    id: form.id,
    archived: form.archived,
    name: form.name,
    moduleName: form.refModel,
    fields: SortBy(
      transformFields(form.fieldList || [], form.refModel),
      'order'
    ),
    systemFields: (form.fieldList || [])
      .filter((f) => f.type === 'SystemFieldRest')
      .map((f) => f.systemType),
  }
}

export function transformFields(fields, moduleName) {
  const transformedFields = []
  fields
    .filter((f) => !f.sectionId)
    .forEach((field) => {
      const transformedField = transformField(field, moduleName)
      if (field.subType === 'section') {
        transformedField.fields = SortBy(
          fields
            .filter((f) => f.sectionId === transformedField.id)
            .map((i) => transformField(i, moduleName)),
          'order'
        )
      }
      transformedFields.push(transformedField)
    })
  return transformedFields
}

export function flattenFields(fields) {
  return fields.reduce((result, field) => {
    const subFields = field.fields || []
    const currentField = Omit(field, 'fields')
    return result.concat(currentField, subFields)
  }, [])
}

export function getFieldIds(fields) {
  return flattenFields(fields).map((f) => f.paramName || f.id)
}

export function nestFields(fields) {
  return (fields || [])
    .filter((f) => !f.sectionId)
    .map((f) => ({
      ...f,
      fields: fields.filter((subField) => subField.sectionId === f.id),
    }))
}

// transform dependent field's tree property
export function transformTreePropertyRecursive(tree) {
  return transformRecursive(tree, transformDependentFieldOptions)
}

// transform dependent field's tree property for server
export function transformTreePropertyRecursiveForServer(tree) {
  return transformRecursiveForBuildTree(
    tree,
    transformDependentFieldOptionsForServer
  )
}

// api field converter for client
export function transformApiField(field) {
  return {
    apiFieldType: field.fieldType,
    requestType: field.requestType,
    url: field.url,
    secureApi: field.secureApi,
    userName: field.userName,
    password: field.password,
    pluginIdentifier: field.pluginIdentifier,
    pathvariable: field.pathvariable,
    requestParam: field.requestParam,
    inputMapping: (field.inputMapping || []).map((m) => ({
      ...m,
      guid: generateId(),
    })),
    outputMapping: (field.outputMapping || []).map((m) => ({
      ...m,
      guid: generateId(),
    })),
    requestJson: field.requestJson,
    message: field.message,
  }
}

// api field converter for server
export function transformApiFieldForServer(field) {
  return {
    fieldType: field.apiFieldType,
    requestType: field.requestType,
    url: field.url,
    secureApi: field.secureApi,
    userName: field.userName,
    password: field.password,
    pluginIdentifier: field.pluginIdentifier,
    pathvariable: field.pathvariable,
    requestParam: field.requestParam,
    inputMapping: field.inputMapping,
    outputMapping: field.outputMapping,
    requestJson: field.requestJson,
    message: field.message,
  }
}

export function transformFieldAttributes(attributes, type) {
  return {
    ...attributes,
    ...(attributes.allowOnlyToSelectDependentLeafNode
      ? {
          allowOnlyToSelectDependentLeafNode:
            attributes.allowOnlyToSelectDependentLeafNode !== 'false',
        }
      : {}),
    ...(attributes.displayHierarchyInReport
      ? {
          displayHierarchyInReport:
            attributes.displayHierarchyInReport !== 'false',
        }
      : {}),
    ...(attributes.allowDecimal
      ? { allowDecimal: attributes.allowDecimal !== 'false' }
      : {}),
    ...(attributes.allowTime
      ? { allowTime: attributes.allowTime !== 'false' }
      : {}),
    ...(attributes.systemManagedField
      ? { systemManagedField: attributes.systemManagedField !== 'false' }
      : {}),
    ...(attributes.inputWidth
      ? { inputWidth: parseInt(attributes.inputWidth, 10) }
      : {
          inputWidth: type === 'textarea' || type === 'description' ? 100 : 40,
        }),
    ...(attributes.displayOptionVertically
      ? {
          displayOptionVertically:
            attributes.displayOptionVertically !== 'false',
        }
      : {}),
  }
}

export function transformField(field, moduleName) {
  let type = Invert(fieldTypeMap)[field.type]
  if (!type) {
    throw new Error(`Field with type ${type} is not defined`)
  }
  if (field.type === 'DropDownFieldRest') {
    type = 'dropdown'
  }
  if (field.radioButtonField) {
    type = 'radio'
  }
  if (field.type === 'DisplayFieldRest') {
    type = field.subType
  }
  return {
    id: field.id,
    moduleName,
    type,
    isSystemField: field.type === 'SystemFieldRest',
    ...(field.type === 'SystemFieldRest' ? { paramName: field.paramName } : {}),
    inputType: field.type === 'SystemFieldRest' ? field.systemType : type,
    guid: generateId(),
    title: field.name,
    attributes: field.attributes
      ? transformFieldAttributes(field.attributes, type)
      : {
          widthClass: 'w-full',
          placeholder: field.name,
        },
    defaultValue: field.defaultValue,
    order: field.orderInField,
    question: field.question,
    maxRating: field.maxRating,
    fLabel: field.fLabel,
    tLabel: field.tLabel,
    minValue: field.minValue,
    maxValue: field.maxValue,
    required: field.required,
    markAsHidden: field.markAsHidden,
    useOnPortal:
      field.systemType === 'template' && !isLoggedIn()
        ? false
        : field.requesterViewOnly,
    // access level for requester
    requesterGroups: field.groupIds || [],
    sectionId: field.sectionId,
    canDelete: field.type === 'SystemFieldRest' ? false : field.deleteableOob,
    canUpdate: field.updatebleOob,
    stage: field.stage,
    requesterCanEdit: field.requesterCanEdit,
    requesterRequired: field.requesterRequired,
    fields: [],
    ...(['dropdown', 'checkbox', 'radio'].indexOf(type) >= 0
      ? { options: field.options }
      : {}),
    ...(type === 'dependent' && field.tree
      ? {
          tree: transformTreePropertyRecursive(
            SortBy(field.tree || [], (d) => d.node.objOrder)
          ),
        }
      : {}),
    ...(type === 'checkbox' ? { defaultValue: field.defaultValue || [] } : {}),
    // for purchase cost fied (price field)
    percentageField: field.percentageField,
    hidden: field.hidden,
    // api field properties for client
    ...(type === 'api' ? { apiFieldDetails: transformApiField(field) } : {}),
  }
}

export function transformFormFieldForServer(field) {
  const type =
    fieldTypeMap[
      field.isSystemField ? 'SystemFieldRest' : field.inputType || field.type
    ]
  if (!type) {
    throw new Error(`field with type ${type} is not defined`)
  }
  return {
    id: field.id,
    type,
    ...(type === 'DisplayFieldRest' ? { subType: field.type } : {}),
    name: field.title,
    attributes: field.attributes,
    orderInField: field.order,
    question: field.question,
    maxRating: field.maxRating,
    fLabel: field.fLabel,
    tLabel: field.tLabel,
    minValue: field.minValue,
    maxValue: field.maxValue,
    required: field.required,
    markAsHidden: field.markAsHidden,
    sectionId: field.sectionId,
    requesterCanEdit: field.requesterCanEdit,
    requesterRequired: field.requesterRequired,
    defaultValue:
      field.defaultValue &&
      (!field.attributes.systemManagedField ||
        field.attributes.systemManagedField === 'false')
        ? field.defaultValue
        : null,
    ...('useOnPortal' in field
      ? {
          requesterViewOnly: field.useOnPortal,
          // access level for requester
          ...(field.useOnPortal
            ? {
                groupIds: field.requesterGroups || [],
              }
            : {
                groupIds: [],
              }),
        }
      : {}),
    ...(field.isSystemField ? { systemType: field.type } : {}),
    ...(['dropdown', 'checkbox', 'radio'].indexOf(field.type) >= 0
      ? { options: field.options }
      : {}),
    ...(field.type === 'dependent' && field.tree
      ? { tree: transformTreePropertyRecursiveForServer(field.tree || []) }
      : {}),
    ...(field.type === 'radio' ? { radioButtonField: true } : {}),
    ...('stage' in field ? { stage: field.stage } : {}),
    // for purchase cost field (price field)
    percentageField: field.percentageField,
    hidden: field.hidden,
    ...('costField' in field ? { costField: field.costField } : {}),
    // transform api field property for server
    ...(field.type === 'api'
      ? transformApiFieldForServer(field.apiFieldDetails)
      : {}),
  }
}

export function isListField(fieldType) {
  return ['checkbox', 'radio', 'dropdown'].indexOf(fieldType) >= 0
}

export function getAdditionalFieldProps(field) {
  if (isListField(field.type)) {
    return {
      options: field.options.map((o) => ({ key: o, text: o })),
    }
  }
  if (field.type === 'dependent') {
    return {
      options: field.tree,
    }
  }
  if (field.type === 'number') {
    return {
      allowDecimal: field.attributes.allowDecimal === 'true',
    }
  }
  return {}
}

export function buildInputTypeForField(field) {
  if (isListField(field.type)) {
    return 'dropdown'
  }
  if (field.type === 'number') {
    return 'number'
  }
  if (field.type === 'dependent') {
    return 'dependent'
  }
  if (field.type === 'datetime') {
    // @TODO add datetime support in future
    // if (field.attributes.allowTime) {
    //   return 'dateTime'
    // }
    return 'date'
  }
  if (field.type === 'boolean') {
    return 'boolean'
  }
  return 'text'
}

export function buildOperatorsForField(field) {
  const operatorMaps = getOperatorMaps()
  if (field.type === 'checkbox') {
    return [...operatorMaps.tag, ...operatorMaps.null_not_null]
  }
  if (field.type === 'dependent') {
    return operatorMaps.list
  }
  if (isListField(field.type)) {
    return [...operatorMaps.list, ...operatorMaps.blank_not_blank]
  }
  if (field.type === 'number') {
    return operatorMaps.number
  }
  if (field.type === 'datetime') {
    return operatorMaps.date
  }
  if (field.type === 'boolean') {
    return operatorMaps.boolean
  }
  return operatorMaps.text
}

export function buildValueTypeForField(field) {
  if (field.type === 'datetime') {
    return 'long'
  }
  if (field.type === 'number') {
    return 'double'
  }
  if (field.type === 'dependent') {
    return 'long'
  }
  if (field.type === 'boolean') {
    return 'boolean'
  }
  return 'string'
}

export const disabledSystemFieldsBehaviour = [
  // refactor requester_email to requester
  'requester',
  'subject',
  'rating',
  'requesterId',
  'displayName',
]

export const useOnPortalEnabledModules = [
  CONSTANTS.REQUEST,
  CONSTANTS.SERVICE_CATALOG,
]

export const hideRequesterSection = ['company']

export const alwaysEditableSystemFields = ['requester', 'subject']
// mandatory field which are used in form rules specially hide rule (set default value on form load)
// after apply form rule, hidden field value must be default value
// mandatorySystemFields is a list of field which are handle in formconsumer form rule
export const mandatorySystemFields = ['status', 'priority', 'impact', 'urgency']

export function transformSystemFieldSupportedData(data) {
  return {
    displayName: data.displayName,
    hideSupported: data.hideSupported,
    paramName: data.paramName,
    type: data.type,
  }
}
