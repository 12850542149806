import Invert from 'lodash/invert'
import { getRootTranslator } from '@utils/get-module-translator'
import {
  transformOperator,
  transformOperatorForServer,
  unaryOperators,
} from './operator'
import { transformValueTypeForServer, transformValueType } from './value-type'
import {
  buildFlatQualificationStructure,
  OperandTypeMap,
} from './qualification'

export function transformSearchForServer(moduleName, search) {
  return {
    model: moduleName,
    name: search.name,
    visibleTo: search.scope,
    groupId: search.scope === 'technician_in_group' ? search.group : null,
    qualification: transformSearchCriteriasForServer(
      moduleName,
      search.qualification
    ),
  }
}

export function transformSearch(moduleName, search) {
  return {
    key: search.id,
    id: search.id,
    name: search.name,
    scope: search.visibleTo,
    systemName: (search.systemName || '').toLowerCase(),
    group: search.groupId,
    canUpdate: search.updatebleOob,
    canDelete: search.deleteableOob,
    createdAt: search.createdTime,
    qualifications: ((search.qualification || {}).quals || []).reduce(
      (result, q) => result.concat(transformSearchCriteria(moduleName, q)),
      []
    ),
  }
}

export function transformSearchCriteria(moduleName, qualification) {
  const __t = getRootTranslator()
  if (qualification.type === 'KeywordQualificationRest') {
    return qualification.keywordSet.map((k) => ({
      valueType: 'keyword',
      keyword: k,
      value: k,
    }))
  } else {
    const operator = transformOperator(qualification.operator)
    const reverseOperandType = Invert(OperandTypeMap)
    return [
      {
        paramName: String(
          qualification.leftOperand.key || qualification.leftOperand.value || ''
        ).replace(`${moduleName}.`, ''),
        operator: {
          name: __t(operator),
          value: operator,
        },
        paramType: reverseOperandType[qualification.leftOperand.type],
        ...(unaryOperators.indexOf(operator) === -1
          ? {
              ...(qualification.rightOperand.type === 'VariableOperandRest'
                ? { value: qualification.rightOperand.value, valueType: 'long' }
                : operator === 'between'
                ? {
                    ...transformValueType({
                      type: qualification.rightOperand.value.type,
                      value: qualification.rightOperand.value.value[0],
                    }),
                    toValue: qualification.rightOperand.value.value[1],
                  }
                : transformValueType(qualification.rightOperand.value)),
            }
          : {}),
        // add left operand additional params for dependentField is true
        ...(qualification.leftOperand.dependentField
          ? { leftOperandAdditionalParams: { dependentField: true } }
          : {}),
      },
    ]
  }
}

export function transformSearchCriteriasForServer(moduleName, searchCriterias) {
  const keywords = searchCriterias
    .filter(({ valueType }) => valueType === 'keyword')
    .map(({ value }) => value)
  return buildFlatQualificationStructure([
    ...searchCriterias
      .filter(({ valueType }) => valueType !== 'keyword')
      .map((c) => transformSearchCriteriaForServer(moduleName, c)),
    ...(keywords.length ? [transformKeywordCriteriaForServer(keywords)] : []),
  ])
}

export function transformKeywordCriteriaForServer(keywords) {
  return {
    type: 'KeywordQualificationRest',
    keywordSet: keywords,
  }
}

export function transformSearchCriteriaForServer(moduleName, searchCriteria) {
  const operator = searchCriteria.operator.value
  if (unaryOperators.indexOf(operator) >= 0) {
    return {
      type: 'UnaryQualificationRest',
      leftOperand: buildLeftOperand(
        moduleName,
        searchCriteria.paramName,
        searchCriteria.paramType,
        // add left operand additional params for dependentField is true
        searchCriteria.leftOperandAdditionalParams
      ),
      operator: transformOperatorForServer(searchCriteria.operator.value),
    }
  }
  return {
    type: 'RelationalQualificationRest',
    leftOperand: buildLeftOperand(
      moduleName,
      searchCriteria.paramName,
      searchCriteria.paramType,
      // add left operand additional params for dependentField is true
      searchCriteria.leftOperandAdditionalParams
    ),
    operator: transformOperatorForServer(searchCriteria.operator.value),
    rightOperand: transfromRightOperandForServer(searchCriteria),
  }
}

function transfromRightOperandForServer(searchCriteria) {
  // if its variable operand
  if (
    searchCriteria.valueType === 'long' &&
    /^[a-z]+/.test(searchCriteria.value)
  ) {
    return {
      type: 'VariableOperandRest',
      value: searchCriteria.value,
    }
  }
  return {
    type: 'ValueOperandRest',
    ...transformValueTypeForServer(
      searchCriteria.valueType,
      searchCriteria.operator.value === 'between'
        ? [searchCriteria.value, searchCriteria.toValue]
        : searchCriteria.value
    ),
  }
}

function buildLeftOperand(
  moduleName,
  paramName,
  paramType = 'property',
  leftOperandAdditionalParams = {}
) {
  if (/^\d+$/.test(paramName)) {
    return {
      type: 'CustomFieldOperandRest',
      key: Number(paramName),
      // add left operand additional params for dependentField is true
      ...(leftOperandAdditionalParams || {}),
    }
  } else {
    return {
      type: OperandTypeMap[paramType],
      ...(paramType !== 'variable'
        ? {
            key: `${
              paramType === 'property' ? `${moduleName}.` : ''
            }${paramName}`,
          }
        : {}),
      ...(paramType === 'variable' ? { value: paramName } : {}),
    }
  }
}
