import Moment from 'moment'
import { getSavedState } from '@state/modules/auth'

import(/* webpackChunkName: "utils" */ 'moment-timezone')

export default function datetime(
  value,
  format = 'ddd, MMM DD, YYYY hh:mm A',
  useTime = true
) {
  if (!value || value <= 0) {
    return ''
  }
  if (!useTime) {
    format = format.replace(' hh:mm A', '')
  }
  const currentUser = getSavedState('auth.user')
  let timezone
  if (currentUser && currentUser.timezone) {
    timezone = currentUser.timezone
  }
  if (Moment.isMoment(value)) {
    if (timezone) {
      return value.tz(timezone).format(format)
    }
    return value.format(format)
  }
  if (/^\d+$/.test(value)) {
    if (timezone) {
      return Moment.unix(value / 1000)
        .tz(timezone)
        .format(format)
    }
    return Moment.unix(value / 1000).format(format)
  }
  return value
}
