import { transformRecursive } from './recursive'

export function transformLocationRecursive(locations) {
  return transformRecursive(locations, transformLocation)
}

export function transformLocation(location) {
  return {
    id: location.id,
    parentId: location.parentId,
    value: location.id,
    label: location.name,
    order: location.objOrder,
    name: location.name,
    description: location.description,
    barCodePrefix: location.barCodePrefix,
    disabled: location.disabled,
    archived: location.removed,
    children: [],
    canDelete: location.deleteableOob,
    canEdit: location.updatebleOob,
  }
}
export function transformLocationForServer(location) {
  return {
    // objOrder: location.order,
    parentId: location.parentId,
    name: location.name,
    description: location.description,
    barCodePrefix: location.barCodePrefix,
  }
}
