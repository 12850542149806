export function transformContractTypeForServer(contract) {
  return {
    id: contract.id,
    name: contract.name,
    description: contract.description,
  }
}

export function transformContractTypeForClient(contract) {
  return {
    id: contract.id,
    name: contract.name,
    description: contract.description,
    canEdit: contract.updatebleOob,
    canDelete: contract.deleteableOob,
  }
}

export function transformContract(contract) {
  return {
    id: contract.id,
    parentId: contract.parentId,
    name: contract.name,
    displayName: contract.displayName,
    subject: contract.displayName,
    description: contract.description,
    contractNumber: contract.contractNumber,
    technicianId: contract.technicianId,
    contractStatus: contract.contractStatus,
    contractTypeId: contract.contractTypeId,
    vendorId: contract.vendorId,
    activationStartTime: contract.activationStartTime,
    activationEndTime: contract.activationEndTime,
    renewalDate: contract.renewalDate,
    maintainceCost: contract.maintainceCost,
    tags: contract.tags,
    renewalParentId: contract.renewalParentId,
    userIds: contract.userIds,
    notifyEmails: contract.notifyEmails,
    notifyBeforeDays: contract.notifyBeforeDays,
    sendExpireBeforeNotificationAlert:
      contract.sendExpireBeforeNotificationAlert,
    renewedStatus: contract.renewedStatus,
    renewed: contract.renewed,
    fileAttachments: contract.fileAttachments,
    departmentId: contract.departmentId,
    shoulCancelChildContracts: contract.shoulCancelChildContracts,
    childCancelDescription: contract.childCancelDescription,
    archived: contract.removed,
    createdAt: contract.createdTime,
    updatedAt: contract.updatedTime,
    createdBy: contract.createdById,
    fieldValueDetails: contract.fieldValueDetails || {},
    ...(contract.fieldValueDetails || {}),
    relationId: contract.relationId,
  }
}

export function transformContractForServer(contract) {
  const transformedProps = ['fieldValueDetails']
  const obj = {
    ...(contract.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...contract.fieldValueDetails,
          },
        }
      : {}),
  }
  Object.keys(contract).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = contract[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: contract[paramName],
        }
      }
    } else if (!transformedProps.includes(paramName)) {
      obj[paramName] = contract[paramName]
    }
  })
  if ('description' in contract) {
    obj.description = contract.description || ''
  }
  return obj
}
