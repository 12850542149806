import { generateId } from '@utils/id'
import { getRootTranslator } from '@utils/get-module-translator'
import {
  transformAvailableQualification,
  transformQualificationForServer,
  transformQualification,
} from './qualification'
const __rootT = getRootTranslator()

export const mandatorySystemFieldParamNames = [
  'statusId',
  'urgencyId',
  'priorityId',
  'impactId',
]

export function transformAction(action) {
  return {
    guid: generateId(),
    value: action.dbKeys,
    action: action.actionType,
    fieldId: action.fieldKey,
    fieldValue: action.fieldValue,
  }
}

export function transformActionForServer(action) {
  return {
    dbKeys: action.value,
    actionType: action.action,
    fieldKey: action.fieldId,
    fieldValue: action.fieldValue,
  }
}

export const executionTypeMap = {
  create_and_edit: 'on_create_and_update',
  create: 'on_create',
  edit: 'on_update',
}

export const userTypeMap = {
  all: 'all_users',
  technician: 'all_technicians',
  requester: 'all_requesters',
  logged_in_user: 'all_logged_in_users',
}

export const eventTypeMap = {
  load: 'on_form_load',
  change: 'on_field_change',
  submit: 'on_form_submit',
}

export const ruleExecutionOptions = () => {
  return [
    {
      text: __rootT('on_create_and_update'),
      value: 'on_create_and_update',
    },
    { text: __rootT('on_create'), value: 'on_create' },
    {
      text: __rootT('on_update'),
      value: 'on_update',
    },
  ]
}

export const ruleApplicableOptions = () => {
  return [
    { text: __rootT('all_users'), value: 'all_users' },
    {
      text: __rootT('all_technicians'),
      value: 'all_technicians',
    },
    {
      text: __rootT('all_requesters'),
      value: 'all_requesters',
    },
    {
      text: __rootT('all_logged_in_users'),
      value: 'all_logged_in_users',
    },
  ]
}
export const ruleEventOptions = () => {
  return [
    { text: __rootT('on_form_load'), value: 'on_form_load' },
    {
      text: __rootT('on_field_change'),
      value: 'on_field_change',
    },
    {
      text: __rootT('on_form_submit'),
      value: 'on_form_submit',
    },
  ]
}
export const actionOptions = () => {
  return [
    { text: __rootT('show'), key: 'show', reverse: 'hide', type: 'attribute' },
    { text: __rootT('hide'), key: 'hide', reverse: 'show', type: 'attribute' },
    {
      text: __rootT('mandate'),
      key: 'mandate',
      reverse: 'non_mendate',
      type: 'attribute',
    },
    {
      text: __rootT('non_mendate'),
      key: 'non_mendate',
      reverse: 'mandate',
      type: 'attribute',
    },
    {
      text: __rootT('enable'),
      key: 'enable',
      reverse: 'disable',
      type: 'attribute',
    },
    {
      text: __rootT('disable'),
      key: 'disable',
      reverse: 'enable',
      type: 'attribute',
    },
    {
      text: __rootT('set_value'),
      key: 'set_value',
      reverse: 'clear_value',
      type: 'value',
    },
    {
      text: __rootT('clear_value'),
      key: 'clear_value',
      reverse: 'set_value',
      type: 'value',
    },
    {
      text: __rootT('show_options'),
      key: 'show_options',
      reverse: 'hide_options',
      type: 'option',
    },
    {
      text: __rootT('hide_options'),
      key: 'hide_options',
      reverse: 'show_options',
      type: 'option',
    },
  ]
}

export function transformSupportData(data) {
  return {
    qualifications: (data.qualPropList || []).map(
      transformAvailableQualification
    ),
    actions: (data.actionKeys || []).map((o) => ({
      text: o.value,
      key: o.key,
    })),
  }
}

export function transformFormRuleForServer(rule) {
  return {
    name: rule.name,
    description: rule.description,
    formRuleExecutionOn: rule.formRuleExecutionOn,
    formRuleUserType: rule.formRuleUserType,
    formRuleEvent: rule.formRuleEvent,
    fieldsChangedTrigger: rule.fieldsChangedTrigger,
    qualification: transformQualificationForServer(rule.qualifications),
    actions: (rule.actions || []).map(transformActionForServer),
    ...(((rule.qualifications || {}).groups || []).length
      ? { reversibleAction: rule.reversibleAction }
      : { reversibleAction: false }),
    ...('enabled' in rule ? { disabled: !rule.enabled } : {}),
  }
}

export function transformFormRuleForList(rule) {
  return {
    id: rule.id,
    name: rule.name,
    ...(rule.refId ? { parentId: rule.refId } : {}),
    formRuleExecutionOn: rule.formRuleExecutionOn,
    formRuleUserType: rule.formRuleUserType,
    formRuleEvent: rule.formRuleEvent,
    fieldsChangedTrigger: rule.fieldsChangedTrigger,
    moduleName: rule.refModel,
    qualifications: transformQualification(
      rule.qualification || { groups: [], operators: [] }
    ),
    actions: (rule.actions || []).map(transformAction),
    reversibleAction: rule.reversibleAction,
    description: rule.description,
    canDelete: rule.deleteableOob,
    enabled: !rule.disabled,
    canEdit: rule.updatebleOob,
    createdAt: rule.createdTime,
  }
}
