import {
  transformValueType,
  transformValueTypeForServer,
} from '@data/value-type'

const PermissionMap = {
  can_manage_service_catalog: 'admin.service_catalog',
  can_manage_request_management: 'admin.request_management',
  can_manage_problem_management: 'admin.problem_management',
  can_manage_change_management: 'admin.change_management',
  can_manage_release_management: 'admin.release_management',
  can_manage_organization: 'admin.organization',
  can_manage_support_channels: 'admin.support_channels',
  can_manage_automation: 'admin.automation',
  can_manage_knowledge: 'admin.knowledge_management',
  can_manage_users: 'admin.users',
  can_manage_asset_management: 'admin.asset_management',
  can_manage_contract: 'admin.contract_management',
  can_manage_asset_discovery: 'admin.asset_discovery',
  can_manage_catalogs: 'admin.asset_catalogs',
  can_manage_purchase: 'admin.purchase_management',
  can_manage_user_survey: 'admin.user_survey_management',
  can_manage_project_management: 'admin.project_management',
  project_can_manage_planning: 'project.manage_planning',
  project_can_manage_members: 'project.manage_members',
  can_manage_patch_management: 'admin.patch_management',
  can_manage_deployment_management: 'admin.deployment',
  hardware_software_asset: 'asset.hardware_software_asset',
  non_it_asset: 'asset.non_it_asset',
  software_license_can_view: 'asset.software_license_can_view',
}

function transformAllowedModulePermissions(modules = {}) {
  const allowedModules = Object.keys(modules)
  Object.keys(modules).forEach((key) => {
    modules[key].forEach((permissionObj) => {
      if (PermissionMap[permissionObj.permission]) {
        allowedModules.push(PermissionMap[permissionObj.permission])
      }
    })
  })
  return allowedModules
}

export function transformPreference(preference) {
  const modulePreferences = {}
  let p = preference.moduleUserPreferences || []
  p.forEach((p) => {
    modulePreferences[p.model] = transformModulePreference(p)
  })
  return {
    modulePreferences,
    pageSize: preference.globalDefaultPageSize,
    theme: preference.theme,
    shortcutEnabled: !preference.shortcutDisabled,
    showUiNotification: preference.showUiNotification,
    webNotificationPrefs: preference.webNotificationPrefs,
    supportChat: preference.supportChat,
    supportContactOps: preference.supportContactOps,
    myAllowedModules: transformAllowedModulePermissions(
      preference.supportedRolesModule
    ),
  }
}

export function transformModulePreference(modulePreference) {
  return {
    columns: modulePreference.columns,
    columnsForSupportPortal: modulePreference.columnsForSupportPortal,
    kanbanStatusColumns: modulePreference.kanbanStatusColumns,
    defaultLayout: modulePreference.defaultPageLayout === 0 ? 'list' : 'split',
    pageSize: modulePreference.defaultPageSize,
    defaultSearchId: modulePreference.defaultQualification,
    moduleName: modulePreference.model,
  }
}

export function transformPreferenceForServer(preference) {
  return {
    moduleUserPreferences: Object.keys(preference.modulePreferences).map((k) =>
      transformModulePreferenceForServer(preference.modulePreferences[k])
    ),
    globalDefaultPageSize: preference.pageSize,
    shortcutDisabled: !preference.shortcutEnabled,
    showUiNotification: preference.showUiNotification,
    webNotificationPrefs: preference.webNotificationPrefs,
    theme: preference.theme,
  }
}

export function transformModulePreferenceForServer(preference) {
  return {
    columns: preference.columns,
    columnsForSupportPortal: preference.columnsForSupportPortal,
    kanbanStatusColumns: preference.kanbanStatusColumns,
    defaultPageSize: preference.pageSize,
    defaultQualification: preference.defaultSearchId,
    defaultPageLayout: preference.defaultLayout === 'list' ? 0 : 1,
    model: preference.moduleName,
    theme: preference.theme,
  }
}

export const transformTenantPreference = (preferences) => {
  const filteredPreference = preferences.preferences || []
  const map = {}
  filteredPreference.forEach((preference) => {
    map[preference.propertyKey] = {
      propertyKey: preference.propertyKey,
      ...transformValueType(preference.valueRest),
    }
  })
  map.showPassword = preferences.userActivationMethod === 'password'
  map.qrCodeSupport = preferences.qrCodeSupport
  map.enableAutoUnistalltionSoftware =
    preferences.enableAutoUnistalltionSoftware
  map.qrCodeMandatoryPropKeys = preferences.qrCodeMandatoryPropKeys
  map.qrCodeCustomfieldKeys = preferences.qrCodeCustomfieldKeys
  return map
}

export const transformTenantPreferenceForServer = (preference) => ({
  preferences: [
    {
      propertyKey: preference.propertyKey,
      valueRest: transformValueTypeForServer(
        preference.valueType,
        preference.value
      ).value,
    },
  ],
})
