import { generateId } from '@utils/id'
export function transformDepreciation(depreciation) {
  return {
    id: depreciation.id,
    name: depreciation.name,
    salvageAmount: depreciation.salvageAmount,
    depreciationDerivation: depreciation.depreciationDerivation,
    depreciationMethod: depreciation.depreciationMethod,
    depreciationType: depreciation.depreciationType,
    usefulLife: depreciation.usefulLife,
    moduleName: depreciation.refModel,
    refId: depreciation.refId,
    purchaseCost: depreciation.purchaseCost,
    productName: depreciation.productName,
    canDelete: depreciation.deleteableOob,
    canEdit: depreciation.updatebleOob,
  }
}

export function transformDepreciationForServer(depreciation) {
  return {
    name: depreciation.name,
    salvageAmount: depreciation.salvageAmount,
    depreciationDerivation: depreciation.depreciationDerivation,
    depreciationMethod: depreciation.depreciationMethod,
    depreciationType: depreciation.depreciationType,
    usefulLife: depreciation.usefulLife,
  }
}

export function transformCostInfo(cost) {
  return {
    bookValue: cost.bookValue,
    disposalAmount: cost.disposalAmount,
    operationalAmount: cost.operationalAmount,
    othersAmount: cost.othersAmount,
    purchaseAmount: cost.purchaseAmount,
    totalAmount: cost.totalAmount,
  }
}

export function transformCost(cost) {
  return {
    id: cost.id,
    acquireDate: cost.acquireDate,
    description: cost.description,
    amount: cost.amount,
    costFactor: cost.costFactor,
    moduleName: cost.refModel,
    refId: cost.refId,
    canDelete: cost.deleteableOob,
    canEdit: cost.updatebleOob,
  }
}

export function transformCostForServer(cost) {
  return {
    name: cost.name,
    acquireDate: cost.acquireDate,
    description: cost.description,
    amount: cost.amount,
    costFactor: cost.costFactor,
  }
}

export function transformAssetDepreciationForList(depreciation) {
  return {
    id: generateId(),
    depreciationDate: depreciation.depreciationDate,
    amount: depreciation.amount,
    accumulatedAmount: depreciation.accumulatedAmount,
    bookValue: depreciation.bookValue,
    remainingLife: depreciation.remainingLife,
    productName: depreciation.productName,
  }
}
