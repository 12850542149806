import { assetComponentTypeMap } from '@modules/asset/helpers/asset'
import Invert from 'lodash/invert'
import { generateId } from '@utils/id'
import { transformRules, transformRulesForServer } from './baseline'
export function transformAssetForList(asset) {
  return {
    guid: generateId(),
    id: asset.id,
    name: asset.name,
    displayName: asset.displayName,
    type: asset.type,
    model: asset.model,
    assetTypeId: asset.assetTypeId,
    description: asset.description,
    impactId: asset.impactId,
    statusId: asset.statusId,
    origin: asset.origin,
    vendorId: asset.vendorId,
    productId: asset.productId,
    assignedUserIds: asset.assignedUserIds,
    assignedDepartmentId: asset.assignedDepartmentId,
    acquitionTime: asset.acquitionTime,
    assignmentTime: asset.assignmentTime,
    resourceId: asset.resourceId,
    managedById: asset.managedById,
    managedByGroupId: asset.managedByGroupId,
    assetGroupId: asset.assetGroupId,
    userIds: asset.userIds,
    groupIds: asset.groupIds,
    locationId: asset.locationId,
    tags: asset.tags,
    fileAttachments: asset.fileAttachments,
    fieldValueDetails: asset.fieldValueDetails || {},
    parentAssetId: asset.parentAssetId,
    archived: asset.removed,
    slientSwitch: asset.slientSwitch,
    uninstallCommandType: asset.uninstallCommandType,
    canDelete: asset.deleteableOob,
    canEdit: asset.updatebleOob,
    createdAt: asset.createdTime,
    updatedAt: asset.updatedTime,
    relationId: asset.relationId,
    relationType: asset.relationType,
    ...(asset.relationId ? { id: `${asset.id}:${asset.model}` } : {}),
    ...(asset.fieldValueDetails || {}),
    approvalStatus: asset.approvalStatus,
    businessServiceId: asset.businessServiceId,
    companyId: asset.companyId,
    // hardware asset
    ipAddress: asset.ipAddress,
    hostName: asset.hostName,
    domainName: asset.domainName,
    subnetMask: asset.subnetMask,
    barCode: asset.barCode,
    macAddress: asset.macAddress,
    inReview: asset.inReview,
    firstDiscoveredTime: asset.firstDiscoveredTime,
    lastDiscoveredTime: asset.lastDiscoveredTime,
    excludedFromScan: asset.excludedFromScan,
    networkId: asset.networkId,
    exemptSwProhibit: asset.exemptSwProhibit,
    varianceDetected: asset.varianceDetected,
    excludeSWMandatoryScan: asset.excludeSWMandatoryScan,
    isMandatory: asset.mandatory,
    ignoreVersion: asset.ignoreVersion,
    agentActiveStatus: asset.agentActiveStatus,
    rdpRecordingExists: asset.rdpRecordingExists,
    // software asset
    version: asset.version,
    swTypeId: asset.swTypeId,
    swCategoryId: asset.swCategoryId,
    underChangeControl: asset.underChangeControl,
    assetCondition: asset.assetCondition,
    movementStatus: asset.movementStatus,
    swComplianceAlert: asset.swComplianceAlert,
    softwareLicenseId: asset.softwareLicenseId,
    agentName: asset.agentName,
    lastQRAndBarcodeScanBy: asset.lastQRAndBarcodeScanBy,
    lastQRAndBarcodeScanDate: asset.lastQRAndBarcodeScanDate,
  }
}
export function transformVmForDetail(vmDetail) {
  return {
    id: vmDetail.id,
    assetDispalyName: vmDetail.assetDispalyName,
    assetName: vmDetail.assetName,
    cpuLimit: vmDetail.cpuLimit,
    cpuReservation: vmDetail.cpuReservation,
    cpuShares: vmDetail.cpuShares,
    cpuSharesLevel: vmDetail.cpuSharesLevel,
    hardwareAssetId: vmDetail.hardwareAssetId,
    memoryLimit: vmDetail.memoryLimit,
    memoryReservation: vmDetail.memoryReservation,
    memoryShares: vmDetail.memoryShares,
    memorySharesLevel: vmDetail.memorySharesLevel,
  }
}
export function transformSoftwareUninstallTask(task) {
  return {
    id: task.id,
    name: task.name,
    assetId: task.assetId,
    assetName: task.assetName,
    assetType: task.assetType,
    assetTypeId: task.assetTypeId,
    createdTime: task.createdTime,
    assignedUserIds: task.assignedUserIds,
    assetDisplayName: task.assetDisplayName,
    uninstallationDate: task.uninstallationDate,
    unistalltationTimeBy: task.unistalltationTimeBy,
    status: task.status,
    result: task.result,
  }
}
export function transformSoftwareUninstallTasForServer(task) {
  return {
    name: task.name,
    assetId: task.assetId,
    assetName: task.assetName,
    assetType: task.assetType,
    assetTypeId: task.asetTypeId,
    createdTime: task.createdTime,
    assignedUserIds: task.assignedUserIds,
    assetDisplayName: task.assetDisplayName,
    uninstallationDate: task.uninstallationDate,
    unistalltationTimeBy: task.unistalltationTimeBy,
    status: task.status,
    result: task.result,
  }
}
export function transformAssetForDetail(asset) {
  const listProps = transformAssetForList(asset)
  return {
    ...listProps,
  }
}

export function transformAssetForServer(asset) {
  const transformedProps = ['fieldValueDetails', 'guid', 'approvalStatus']
  const obj = {
    ...(asset.fieldValueDetails
      ? {
          fieldValueDetails: {
            ...asset.fieldValueDetails,
          },
        }
      : {}),
  }
  Object.keys(asset).forEach((paramName) => {
    if (/^\d+$/.test(paramName)) {
      if (obj.fieldValueDetails) {
        obj.fieldValueDetails[paramName] = asset[paramName]
      } else {
        obj.fieldValueDetails = {
          [paramName]: asset[paramName],
        }
      }
    } else if (!transformedProps.includes(paramName)) {
      obj[paramName] = asset[paramName]
    }
  })
  if ('description' in asset) {
    obj.description = asset.description || ''
  }
  return obj
}

export function transformAssetComponentForServer(component) {
  return {
    ...component,
    ...('type' in component && component.type
      ? { type: assetComponentTypeMap()[component.type] }
      : {}),
  }
}
export function transformAssetComponent(component) {
  const InvertMap = Invert(assetComponentTypeMap())
  return {
    ...component,
    ...('type' in component && component.type
      ? { type: InvertMap[component.type] }
      : {}),
    ...(component.fieldValueDetails || {}),
  }
}
export function transformAssetProperties(property) {
  return {
    ...property,
    ...(property.fieldValueDetails || {}),
  }
}
export function transformSummaryBaseline(summaryBaseline) {
  return {
    id: summaryBaseline.id,
    name: summaryBaseline.name,
    displayName: summaryBaseline.displayName,
    description: summaryBaseline.description,
    createdTime: summaryBaseline.createdTime,
    createdById: summaryBaseline.createdById,
    publishedDate: summaryBaseline.publishedDate,
    baselineId: summaryBaseline.baselineId,
    rules: summaryBaseline.rules.map(transformRules),
  }
}

export function transformSummaryBaselineForServer(summaryBaseline) {
  return {
    id: summaryBaseline.id,
    name: summaryBaseline.name,
    description: summaryBaseline.description,
    createdTime: summaryBaseline.createdTime,
    createdById: summaryBaseline.createdById,
    rules: summaryBaseline.rules.map(transformRulesForServer),
  }
}
export function transformSummaryVariance(summaryVariance) {
  return {
    id: summaryVariance.id,
    createdTime: summaryVariance.createdTime,
    assetComponent: summaryVariance.assetComponent,
    componentId: summaryVariance.componentId,
    componentModel: summaryVariance.componentModel,
    componentName: summaryVariance.componentName,
    fieldName: summaryVariance.fieldName,
    fromValue: summaryVariance.fromValue,
    toValue: summaryVariance.toValue,
    referenceRollbackRequest: summaryVariance.referenceRollbackRequest,
    status: summaryVariance.status,
    rejectChangeId: summaryVariance.rejectChangeId,
    rejectChangeName: summaryVariance.rejectChangeName,
  }
}
export function transformBaselineHistory(baselineHistory) {
  return {
    id: baselineHistory.id,
    createdById: baselineHistory.createdById,
    createdTime: baselineHistory.createdTime,
    reason: baselineHistory.reason,
    changeId: baselineHistory.changeId,
    changeStatusId: baselineHistory.changeStatusId,
    incidentId: baselineHistory.incidentId,
    incidentName: baselineHistory.incidentName,
    changeName: baselineHistory.changeName,
    incidentStatusId: baselineHistory.incidentStatusId,
    latest: baselineHistory.latest,
    baselineName: baselineHistory.baselineName,
    name: baselineHistory.name,
    rules: baselineHistory.rules.map(transformRules),
  }
}
export function transformBaselineCompareInfo(compareBaseline) {
  return {
    id: generateId(),
    fieldKey: compareBaseline.fieldKey,
    fieldName: compareBaseline.fieldName,
    baselineOneValue: compareBaseline.baseline1Value,
    baselineTwoValue: compareBaseline.baseline2Value,
    baseline1Name: compareBaseline.baseline1Name,
    baseline2Name: compareBaseline.baseline2Name,
    different: compareBaseline.different,
  }
}
export function transformVarianceHistory(varianceHistory) {
  return {
    id: varianceHistory.id,
    name: varianceHistory.name,
    createdTime: varianceHistory.createdTime,
    componentId: varianceHistory.componentId,
    componentModel: varianceHistory.componentModel,
    componentName: varianceHistory.componentName,
    fieldName: varianceHistory.fieldName,
    fromValue: varianceHistory.fromValue,
    toValue: varianceHistory.toValue,
    status: varianceHistory.status,
    actionUserId: varianceHistory.actionUserId,
    actionDate: varianceHistory.actionDate,
    changeId: varianceHistory.changeId,
    changeName: varianceHistory.changeName,
    requestName: varianceHistory.requestName,
    requestId: varianceHistory.requestId,
    referenceRollbackRequest: varianceHistory.referenceRollbackRequest,
    rejectChangeId: varianceHistory.rejectChangeId,
    rejectChangeName: varianceHistory.rejectChangeName,
  }
}

// RDP History
export function transformRdpHistory(rdpHistory) {
  return {
    id: generateId(),
    startTime: rdpHistory.startTime,
    endTime: rdpHistory.endTime,
    duration: rdpHistory.duration,
    userId: rdpHistory.userId,
    rdpUserIds: rdpHistory.rdpUserIds,
  }
}

export function transformUsedByOrLocationHistory(history) {
  return {
    id: history.id,
    startTime: history.startTime,
    endTime: history.endTime,
    value: history.value,
    duration: history.duration,
    rdpUserIds: history.rdpUserIds,
  }
}
