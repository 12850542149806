export function transformRDPRecording(config) {
  return {
    id: config.id,
    fileName: config.fileName,
    status: config.status,
    createdAt: config.createdTime,
    converted: config.converted,
    m4vFileName: config.m4vFileName,
    m4vFileSize: config.m4vFileSize,
  }
}

export function transformRdpConfiguration(config) {
  return {
    enableRdp: config.enableRdp,
    onlyAllowDepartmentManagerToTakeRDP:
      config.onlyAllowDepartmentManagerToTakeRDP,
    enableVideoRecording: config.enableVideoRecording,
    sessionIndication: config.sessionIndication,
    connectionType: config.connectionType,
    rdpConfigType: config.rdpConfigType,
    repeaterHost: config.repeaterHost,
    repeaterPort: config.repeaterPort,
    vncPassword: config.vncPassword,
    vncPort: config.vncPort,
    userConformationRequired: config.userConformationRequired,
    refreshCycle: config.refreshCycle,
  }
}

export function transformRdpConfigurationForServer(config) {
  return {
    enableRdp: config.enableRdp,
    onlyAllowDepartmentManagerToTakeRDP:
      config.onlyAllowDepartmentManagerToTakeRDP,
    enableVideoRecording: config.enableVideoRecording,
    sessionIndication: config.sessionIndication,
    connectionType: config.connectionType,
    rdpConfigType: config.rdpConfigType,
    repeaterHost: config.repeaterHost,
    repeaterPort: config.repeaterPort,
    vncPassword: config.vncPassword,
    vncPort: config.vncPort,
    userConformationRequired: config.userConformationRequired,
    refreshCycle: config.refreshCycle,
  }
}
// RDP agent
export function transformRdpAgents(rdpAgent) {
  return {
    id: rdpAgent.id,
    name: rdpAgent.name,
    hostName: rdpAgent.hostName,
    ipAddress: rdpAgent.ipAddress,
    uuid: rdpAgent.uuid,
    agentVersion: rdpAgent.agentVersion,
    lastRefreshCallTime: rdpAgent.lastRefreshCallTime,
    refreshCycle: rdpAgent.refreshCycle,
    assetId: rdpAgent.assetId,
    repeaterId: rdpAgent.repeaterId,
    osName: rdpAgent.osName,
    active: rdpAgent.active,
  }
}
