import Vue from 'vue'
import VueI18n from 'vue-i18n'
// import en from '@locales/en'
// import gu from '@locales/gu'
// import tl from '@locales/tl'
// import ms from '@locales/ms'
// import th from '@locales/th'
// import id from '@locales/id'
// import ar from '@locales/ar'
import { getLocaleDataApi } from '@api/locale-api'
import ModuleManager from '@modules'
import Bus from '@utils/emitter'

Vue.use(VueI18n)

// const messages = { en, gu, tl, ms, th, id, ar }

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
})

const loadedLanguages = ['en', 'gu', 'tl', 'ms', 'th', 'id', 'ar'] // our default language that is preloaded

/**
 * Set given locale to current i18n instance
 * @param {string} lang
 * @returns {string}
 */
function setI18nLanguage(lang) {
  i18n.locale = lang || process.env.VUE_APP_I18N_LOCALE
  i18n.fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function getCurrentI18nLanguage() {
  return i18n.locale || process.env.VUE_APP_I18N_LOCALE || 'en'
}

/**
 * Lazy load given locale's json file and apply it to application
 * @param {string} lang
 * @returns {Promise<string>}
 */
export function updateApplicationLocale(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      const moduleTranslationKey =
        ModuleManager.getCurrentModule().getTranslationKey()
      return getLocaleDataApi(lang, [moduleTranslationKey]).then(
        (localeMessages) => {
          // merge global messages with local module messages
          const messages = Object.assign(localeMessages, {})
          i18n.setLocaleMessage(lang, messages)
          loadedLanguages.push(lang)
          window.globallyLocaleLoaded = true
          Bus.$emit('app:globallyLocaleLoaded')
          return setI18nLanguage(lang)
        }
      )
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}
// export function updateApplicationLocale(lang) {
//   if (i18n.locale !== lang) {
//     if (!loadedLanguages.includes(lang)) {
//       return Promise.all([
//         import(/* webpackChunkName: "lang-[request]" */ `@locales/${lang}`),
//         ModuleManager.getCurrentModule().getLocaleMessages(lang),
//       ]).then(([globalMessages, moduleMessages]) => {
//         const moduleTranslationKey =
//           ModuleManager.getCurrentModule().getTranslationKey()
//         // merge global messages with local module messages
//         const messages = Object.assign(globalMessages, {
//           [moduleTranslationKey]: moduleMessages,
//         })
//         i18n.setLocaleMessage(lang, messages)
//         loadedLanguages.push(lang)
//         return setI18nLanguage(lang)
//       })
//     }
//     return Promise.resolve(setI18nLanguage(lang))
//   }
//   return Promise.resolve(lang)
// }

export default i18n
