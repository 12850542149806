export function transformSsoConfig(ssoConfig) {
  return {
    id: ssoConfig.id,
    ssoEnabled: ssoConfig.ssoEnabled,
    ssoProvider: ssoConfig.ssoProvider,
    enforceSsoLoginOnly: ssoConfig.enforceSsoLoginOnly,
    autoCreateUser: ssoConfig.autoCreateUser,
    excludedTechnincians: ssoConfig.excludedTechnincians,
    idpEntityId: ssoConfig.idpEntityId,
    idpLoginUrl: ssoConfig.idpLoginUrl,
    idpLogoutUrl: ssoConfig.idpLogoutUrl,
    idpFingerprint: ssoConfig.idpFingerprint,
    spEntityId: ssoConfig.spEntityId,
    spAssertionUrl: ssoConfig.spAssertionUrl,
    spSingleLogoutUrl: ssoConfig.spSingleLogoutUrl,
    attachments: ssoConfig.attachments,
    spPublicKeyFiles: ssoConfig.spPublicKeyFiles,
    spPrivateKeyFiles: ssoConfig.spPrivateKeyFiles,
  }
}

export function transformSsoConfigForServer(ssoConfig) {
  return {
    ssoEnabled: ssoConfig.ssoEnabled,
    ssoProvider: !ssoConfig.ssoProvider ? null : ssoConfig.ssoProvider,
    enforceSsoLoginOnly: ssoConfig.enforceSsoLoginOnly,
    autoCreateUser: ssoConfig.autoCreateUser,
    excludedTechnincians: ssoConfig.excludedTechnincians,
    idpEntityId: ssoConfig.idpEntityId,
    idpLoginUrl: ssoConfig.idpLoginUrl,
    idpLogoutUrl: ssoConfig.idpLogoutUrl,
    idpFingerprint: ssoConfig.idpFingerprint,
    spEntityId: ssoConfig.spEntityId,
    spAssertionUrl: ssoConfig.spAssertionUrl,
    spSingleLogoutUrl: ssoConfig.spSingleLogoutUrl,
    attachments: ssoConfig.attachments,
    spPublicKeyFiles: ssoConfig.spPublicKeyFiles,
    spPrivateKeyFiles: ssoConfig.spPrivateKeyFiles,
  }
}
