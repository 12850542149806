import { generateId } from '@utils/id'
import {
  transformQualificationForServer,
  transformQualification,
} from './qualification'
import { transformActionForServer, transformAction } from './action'

export function transformSla(sla) {
  return {
    id: sla.id,
    name: sla.name,
    description: sla.description,
    moduleName: sla.refModel,
    ...(sla.refId ? { parentId: sla.refId } : {}),
    operational_hours_type: sla.operationHourType,
    responseTime: sla.minRespondTime,
    responseTimeUnit: sla.minRespondTimeUnit,
    resolutionTime: sla.minResolutionTime,
    resolutionTimeUnit: sla.minResolutionTimeUnit,
    qualifications: transformQualification(
      sla.qualification || { groups: [], operators: [] }
    ),
    responseEscalations: (sla.responseTimeEscalationList || []).map(
      transformEscalation
    ),
    resolutionEscalations: (sla.resolutionTimeEscalationList || []).map(
      transformEscalation
    ),
    olaEnabled: sla.olaEnabled,
    olaConfigs: (sla.olaConfigSet || []).map(transformOlaConfig),
    olaEscalations: (sla.olaTimeEscalationList || []).map(transformEscalation),
    canEdit: sla.updatebleOob,
    archived: sla.removed,
  }
}

function transformEscalationForServer(escalation) {
  return {
    id: escalation.id,
    escalationOrder: escalation.order,
    duration:
      escalation.durationType === 'before'
        ? escalation.time * -1
        : escalation.time,
    timeUnit: escalation.timeUnit,
    actions: (escalation.actions || []).map(transformActionForServer),
  }
}

function transformEscalation(escalation) {
  return {
    ...('id' in escalation ? { id: escalation.id } : {}),
    guid: generateId(),
    order: escalation.escalationOrder,
    durationType: escalation.duration < 0 ? 'before' : 'after',
    time: Math.abs(escalation.duration || 0),
    timeUnit: escalation.timeUnit,
    actions: (escalation.actions || []).map(transformAction),
  }
}

function transformOlaConfigForServer(config) {
  return {
    id: config.id,
    olaOrder: config.order,
    olaTime: config.time,
    timeUnit: config.timeUnit,
    groupIds: config.groupIds || [],
  }
}

function transformOlaConfig(config) {
  return {
    ...('id' in config ? { id: config.id } : {}),
    guid: generateId(),
    order: config.olaOrder,
    time: Math.abs(config.olaTime || 0),
    timeUnit: config.timeUnit,
    groupIds: config.groupIds || [],
  }
}

export function transformSlaForServer(sla) {
  return {
    name: sla.name,
    description: sla.description,
    operationHourType: sla.operational_hours_type,
    minRespondTime: sla.responseTime,
    minRespondTimeUnit: sla.responseTimeUnit,
    minResolutionTime: sla.resolutionTime,
    minResolutionTimeUnit: sla.resolutionTimeUnit,
    qualification: transformQualificationForServer(sla.qualifications),
    responseTimeEscalationList: (sla.responseEscalations || []).map(
      (e, index) => transformEscalationForServer({ ...e, order: index + 1 })
    ),
    resolutionTimeEscalationList: (sla.resolutionEscalations || []).map(
      (e, index) => transformEscalationForServer({ ...e, order: index + 1 })
    ),
    olaEnabled: sla.olaEnabled,
    olaConfigSet: (sla.olaConfigs || []).map((e, index) =>
      transformOlaConfigForServer({ ...e, order: index + 1 })
    ),
    olaTimeEscalationList: sla.olaEnabled
      ? (sla.olaEscalations || []).map((e, index) =>
          transformEscalationForServer({ ...e, order: index + 1 })
        )
      : null,
  }
}

export function transformSlaForList(sla) {
  return {
    id: sla.id,
    name: sla.name,
    ...(sla.refId ? { parentId: sla.refId } : {}),
    operational_hours_type: sla.operationHourType,
    moduleName: sla.refModel,
    responseTime: sla.minRespondTime,
    responseTimeUnit: sla.minRespondTimeUnit,
    resolutionTime: sla.minResolutionTime,
    resolutionTimeUnit: sla.minResolutionTimeUnit,
    canDelete: sla.deleteableOob,
    enabled: !sla.disabled,
    canEdit: sla.updatebleOob,
    olaEnabled: sla.olaEnabled,
  }
}
